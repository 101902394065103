import { NgModule } from "@angular/core";
import { BcHeaderModule } from '@bancolombia/design-system-web/bc-header';
import { BcIconModule } from "@bancolombia/design-system-web/bc-icon";
import { BcMenuModule } from '@bancolombia/design-system-web/bc-menu';
import { BcRadioModule } from '@bancolombia/design-system-web/bc-radio';
import { BcCheckboxModule } from '@bancolombia/design-system-web/bc-checkbox';
import { BcModalModule } from '@bancolombia/design-system-web/bc-modal';
import { BcSearchModule } from '@bancolombia/design-system-web/bc-search';
import { BcCarouselModule } from '@bancolombia/design-system-web/bc-carousel';
import { BcPaginatorModule } from '@bancolombia/design-system-web/bc-paginator';
import { BcTableModule } from '@bancolombia/design-system-web/bc-table';
import { BcTabsModule } from '@bancolombia/design-system-web/bc-tabs-group';
import { BcSwitchModule } from '@bancolombia/design-system-web/bc-switch';
import { BcInputModule } from '@bancolombia/design-system-web/bc-input';
import { BcInputSelectModule } from '@bancolombia/design-system-web/bc-input-select';
import { BcCardModule } from '@bancolombia/design-system-web/bc-card';
import { BcButtonModule } from '@bancolombia/design-system-web/bc-button';
import { BcAccordionModule } from '@bancolombia/design-system-web/bc-accordion'
import { BcStepperModule } from "@bancolombia/design-system-web/bc-stepper";
import { BcFooterModule } from "@bancolombia/design-system-web/bc-footer";
import { BcBreadcrumbModule } from "@bancolombia/design-system-web/bc-breadcrumb";
import { BcAlertModule } from '@bancolombia/design-system-web/bc-alert'
import { BcTooltipModule } from "@bancolombia/design-system-web/bc-tooltip";
import { BcInputDateModule } from "@bancolombia/design-system-web/bc-input-date";
import { BcCircleLoadingModule } from "@bancolombia/design-system-web/bc-circle-loading";
import { BcSliderModule } from '@bancolombia/design-system-web/bc-slider';
import { BcIconButtonModule } from '@bancolombia/design-system-web/bc-icon-button';
import { BcStrokeModule } from '@bancolombia/design-system-web/bc-stroke';
import { BcStatusModule } from '@bancolombia/design-system-web/bc-status';

@NgModule({
    declarations: [],
    imports: [
        BcIconModule.forRoot({ path: 'https://library-sdb.apps.bancolombia.com/bds/7.12.9' }),
    ],
    exports: [
        BcHeaderModule,
        BcMenuModule,
        BcIconModule,
        BcAccordionModule,
        BcPaginatorModule,
        BcTableModule,
        BcTabsModule,
        BcRadioModule,
        BcCheckboxModule,
        BcSearchModule,
        BcModalModule,
        BcCardModule,
        BcCarouselModule,
        BcInputSelectModule,
        BcInputModule,
        BcSwitchModule,
        BcButtonModule,
        BcStepperModule,
        BcFooterModule,
        BcBreadcrumbModule,
        BcAlertModule,
        BcTooltipModule,
        BcInputDateModule,
        BcCircleLoadingModule,
        BcSliderModule,
        BcIconButtonModule,
        BcStrokeModule,
        BcStatusModule
    ]
})
export class BcSharedModule { }
